import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpRequestService } from "@ds-common-services/http-services/http-request.service";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { Resource, Service } from "ngx-jsonapi";
import { CommonModel, globalSearchPayload } from "@ds-shared/models/common.model";
import { PAYLOAD } from "@ds-shared/enums/common.enum";
import { Subscription, forkJoin, map, mergeMap } from "rxjs";
import { MixpanelService } from '@ds-common-services/utility-services/mixpanel.service';
import { Modules } from "@ds-shared/enums/modules.enum";
import { DataTransferService } from "../utility-services/data-transfer.service";

export class PopUpGrid extends Resource {
	public attributes = {
		type: '',
		id: '',
	};
}
@Injectable({
	providedIn: 'root'
})
export class PopUpListService extends Service<PopUpGrid>{
	public resource = PopUpGrid;
	public type = '';
	public ttl = 1;
	public setType(val: any) {
		this.type = val;
	}
}

@Injectable({
	providedIn: 'root'
})
export class PopUpListExtraService extends Service<PopUpGrid>{
	public resource = PopUpGrid;
	public type = '';
	public ttl = 1;
	public setType(val: any) {
		this.type = val;
	}
}

// Competitor
export class PopUpGridCompetitor extends Resource {
	public attributes = {
		type: '',
		id: '',
	};
}
@Injectable({
	providedIn: 'root'
})
export class PopUpListCompetitorService extends Service<PopUpGridCompetitor>{
	public resource = PopUpGridCompetitor;
	public type = '';
	public ttl = 1;
	public setType(val: any) {
		this.type = val;
	}
}

@Injectable({
	providedIn: 'root'
})
export class PopUpListCompetitorExtraService extends Service<PopUpGridCompetitor>{
	public resource = PopUpGridCompetitor;
	public type = '';
	public ttl = 1;
	public setType(val: any) {
		this.type = val;
	}
}

@Injectable({
	providedIn: "root"
})
export class GlobalService extends Service<PopUpGrid> {
	constructor(
		public http: HttpRequestService,
		private localStorageService: LocalStorageService,
		private router: Router,
		private commonFunctions: CommonFunctionsService,
		private mixpanelService: MixpanelService,
		private dataTransferService: DataTransferService
	) {
		super();
	}

	checkAuthorization(loginPayload) {
		return this.http.callPostApi("login", loginPayload);
	}

	getResetPasswordUserId(phash: string, email: string) {
		return this.http.callGetApi(`reset-password?phash=${phash}&email=${email}`);
	}

	sendResetPasswordLink(userEmailId: any) {
		return this.http.callPostApi("reset-password", userEmailId);
	}

	getUserInfo() {
		return this.http.callGetApi("user");
	}

	getStores() {
		let path = "customerstores";
		return this.http.callGetApi(path);
	}

	getBulkExport(type): any {
		if (type == "competitor") {
			var getBulkExportPath = "export-profiles";
		} else if (type == "your") {
			var getBulkExportPath = "export-customer-profiles";
		}
		return this.http.getFileAsBlob(getBulkExportPath);
	}

	sendBulkImport(data, type): any {
		if (type == "competitor") {
			return this.http.callUploadFileServiceApiProd("import-profiles", data);
		} else if (type == "your") {
			return this.http.callUploadFileServiceApiProd(
				"import-customer-profiles",
				data
			);
		}
	}

	getSearchedList(keyword, pageNumber) {
		return this.http.callGetApi(
			"advance-search/?query=" +
			keyword +
			"&page[size]=10&page[number]=" +
			pageNumber
		);
	}

	getDetailedViewOfProductDescription(mpProductId) {
		return this.http.callGetApi(
			`common/product-description?marketplace_product_id=${mpProductId}`
		);
	}


	/** Secondary Menu Counts START*/
	public secondaryMenuCount(module: any) {
		return this.http.callGetApi(
			`common/count?${[Modules.scorecard, Modules["share-of-search"]].includes(module)
				? ''
				: `filter[${PAYLOAD.CURRENCY_ID}]=${this.commonFunctions.currencySelected.id}&`
			}${`filter[module]=${module}`}`
		);
	}
	/** Secondary Menu Counts END*/

	public getLocalFilters(
		krReport = false,
		path: string,
		currencyId,
		isSKU = false,
		scorecardInfo,
		callFromReporting?: boolean,
		name?,
		id?,
		harmonised_view?,
		shouldRemoveShowLevel?,
		selectedMPData?,
		level?: { name: number; suffix: string },
		categoryDependencyFilterParam?,
		timelineCode?: string,
		moduleType?: string,
		scorecardProfileView?: string,
		isKeywordFilter?: string

	) {
		let startApiSegment = '';
		if (path === 'price-filter') startApiSegment = 'scorecard'
		else startApiSegment = isSKU ? "common" : krReport ? "reporting" : "common"
		const moduleName = this.commonFunctions.getModuleName();
		let showLevel = false;
		if (
			(moduleName === "sellout" ||
				moduleName === "pricing" ||
				moduleName === "availability") &&
			path === "categories"
		) {
			showLevel = true;
		}
		let url = startApiSegment + '/' + path + 
		(currencyId && path !== "marketplaces"? `?filter[${PAYLOAD.CURRENCY_ID}]=${currencyId}`: "") +
		(
			this.router.url.includes("sellout/sales")
				? `&filter[frequency_type]=c`
				: ""
		) +
		(callFromReporting ? "&is_harmonised=false" : "") +
		(
			scorecardInfo && scorecardInfo.view === "scorecard"
				? `?filter[${scorecardInfo.type}_ids][0]=${scorecardInfo.id}`
				: ""
		) +
		(
			scorecardInfo && scorecardInfo.view === "profile"
				? `?filter[profile_ids][0]=${scorecardInfo.id}`
				: ""
		) +
		(
			scorecardInfo && scorecardInfo.view === "harmonised"
				? `?filter[harmonised_id][0]=${scorecardInfo.id}`
				: ""
		)+
		this.appendMarketplace(selectedMPData) +
		(categoryDependencyFilterParam ? categoryDependencyFilterParam : "");
		if (name === "store" && id) {
			let storeUrl = `${url}
					${id
					? harmonised_view
						? `&filter[harmonised_ids][0]=${id}`
						: `&filter[store_ids][0]=${id}`
					: ""
				}
				
				${timelineCode ? timelineCode : ""}${moduleType ? `&filter[module]=${moduleType}` : ""
				}`;
			return this.http.callGetApi(this.formatUrl(storeUrl));
		} else if (name === "brand" && id) {
			let brandUrl = `${url}
				${id
					? harmonised_view
						? `&filter[harmonised_ids][0]=${id}`
						: `&filter[brand_ids][0]=${id}`
					: ""
				}
			${timelineCode ? timelineCode : ""}${moduleType ? `&filter[module]=${moduleType}` : ""
				}`;
			return this.http.callGetApi(this.formatUrl(brandUrl));
		} else if (name === "category" && id) {
			let categoryUrl = `${url}
			${id
					? harmonised_view
						? `&filter[harmonised_ids][0]=${id}`
						: `&filter[category_ids][0]=${id}`
					: ""
				}${showLevel && !shouldRemoveShowLevel ? "&showLevel=yes" : ""}
		${level ? `&filter[level]=${level.name}` : ""}
		`;
			return this.http.callGetApi(this.formatUrl(categoryUrl));
		} else {
			let genUrl = url +
			(showLevel && !shouldRemoveShowLevel ? "&showLevel=yes" : "") +
			(level ? `&filter[level]=${level.name}` : "") +
			(timelineCode ? timelineCode : "") +
			(
				moduleType
					? `${
							(this.router["url"].includes("scorecard") ||
								this.router["url"].includes("share-of-search")) &&
							path !== "categories" &&
							!scorecardInfo
								? "?"
								: "&"
					  }filter[module]=${moduleType}`
					: ""
			) + 
			(scorecardProfileView ? scorecardProfileView : "") +
			(
				this.router.url.includes("sales/sku-analysis")
					? `&filter[include_harmonisation]=${true}`
					: ""
			) +
			(isKeywordFilter ? isKeywordFilter : "");
			return this.http.callGetApi(this.formatUrl(genUrl));
		}
	}

	public formatUrl(url) {
		//Split the URL into the base part and the query part
		let [base, queryString] = url.split('?');
		if (!queryString) {
			if (url.includes('&')) {
				url = url.replace("&", "?");
				let [baseIn, queryStringIn] = url.split('?');
				return this.replaceFormatURl(baseIn, queryStringIn);
			}
			else return url; // If there's no query string, return the original URL
		}
		else return this.replaceFormatURl(base, queryString);
	}
	public replaceFormatURl(base, queryString) {
		// Split the query string by '?' to handle incorrect separators
		let queries = queryString.split('?');
		// Join the queries with '&' and prefix with '?'
		let formattedQueryString = '?' + queries.join('&');
		return base + formattedQueryString;
	}

	public userLogout() {
		return this.http.callGetApi(`logout`);
	}

	public getMLServiceList() {
		return this.http.callGetApi(`common/ml-services-list`);
	}
	getStoreList(
		marketplaceId: any,
		pageNumber: any,
		search: any,
		currency_id: number
	) {
		return this.http.callGetApi(
			`scorecard/stores?page[size]=10&page[number]=${pageNumber}&marketplace_id=${marketplaceId}${search ? `&filter[search]=${search}` : ""
			}&filter[${PAYLOAD.CURRENCY_ID}]=${currency_id}`
		);
	}

	logout() {
		this.mixpanelService.logout();
		const lang = this.commonFunctions.getLang();
		this.localStorageService.clear();
		this.localStorageService.clearSessionStorage();
		this.commonFunctions.setLang(lang);
		this.removeProductfruitInstance();
		this.router.navigate(["/"]);
	}


	private removeProductfruitInstance() {
		window?.productFruits?.services?.destroy();
	}

	public getExportApi(endpoint: string) {
		return this.http.fetchBlob(endpoint);
	}

	public callSaveWidgetAPI(payload) {
		return this.http.callPostApi(`reporting/save-widget`, payload);
	}
	public getSaveWidgetAPI() {
		return this.http.callGetApi(`reporting/save-widget`);
	}
	public deleteSaveWidgetAPI(widgetID) {
		return this.http.callDeleteApi(`reporting/save-widget?id=${widgetID}`);
	}

	getRecentGlobalSearch(type: string) {
		return this.http.callGetApi(
			`advance-search/recent-search?currency_id=${this.commonFunctions.currencySelected?.id}&search_type=${type}`
		);
	}
	callAdvanceGlobalSearch(payload: globalSearchPayload) {
		return this.http.callPostApi("advance-search/global-search", payload);
	}
	callAdvanceGlobalRecentSearch(payload: globalSearchPayload) {
		return this.http.callPostApi("advance-search/recent-search ", payload);
	}
	public getCurrencyDetails(
		type: string,
		id?: number | string,
		view: string = "scorecard",
		url?: string
	) {
		return this.http.callGetApi(
			`common/currency-provider?filter[scorecard_type]=${type}${id ? `&filter[${view}_id]=${id}` : ""
			}${url ? `&filter[${view}_url]=${url}` : ""}`
		);
	}
	callRangeFilterApi(param: any) {
		return this.http.callPostApi(
			`reporting/filters-values?is_harmonised=false`,
			param
		);
	}
	public getCampaignStatus(
		scorecardId?: number,
		type?: string,
		view?: string,
		currencyId?: number
	) {
		const queryParams = [];
		if (scorecardId) queryParams.push(`filter[${view}_id]=${scorecardId}`);
		if (type) queryParams.push(`filter[scorecard_type]=${type}`);
		if (currencyId) queryParams.push(`filter[currency_id]=${currencyId}`);
		const queryString =
			queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
		const url = `common/campaign-check${queryString}`;
		return this.http.callGetApi(url);
	}

	public getAiStatus(
		moduleType: Modules,
		ai_type: "cq" | "sa" | "pm",
		currency_id?: number,
		timeline?: string,
		ai_service?: boolean,
		extraParams: string[] = []
	) {
		const queryParams = [
			`filter[module_type]=${moduleType}`,
			`filter[ai_type]=${ai_type}`,
			...extraParams
		];
		if (timeline) queryParams.push(`filter[timeline]=${timeline}`);
		if (currency_id) queryParams.push(`filter[currency_id]=${currency_id}`);
		if (ai_service) queryParams.push(`filter[ai_service]=${ai_service}`);
		const queryString =
			queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
		const url = `common/ai-status${queryString}`;

		return this.http.callGetApi(url);
	}
	callMissingKeywordProdList(keyword) {
		return this.http.callGetApi(
			`scorecard/keywords-products?phrase=${keyword}`
		);
	}

	public getMarketplaceConfigurations() {
		const globalFilters = this.localStorageService.get(
			this.localStorageService.globalFilterStorageKey
		);
		const selectedCurrencyId = this.commonFunctions.currencySelected.id;
		const marketplace_ids = globalFilters.marketplace.map((item) => item.id);
		return this.http.callGetApi(
			`common/marketplace-configuration?filter[currency_id]=${selectedCurrencyId}${marketplace_ids.length
				? `&filter[marketplace_id]=${marketplace_ids}`
				: ""
			}`
		);
	}

	public getMarketplaceConfiguration() {
		this.getMarketplaceConfigurations().subscribe((res: CommonModel) => {
			this.dataTransferService.sendMarketplaceConfiguration(res.data as []);
		});
	}
	updateNewPasswordApi(data: { email: string; new_password: string }) {
		return this.http.callPutApi(`reset-password`, data);
	}
	private appendMarketplace(payload) {
		if (payload?.marketplace?.length) {
			return (
				"&" +
				payload.marketplace
					.map(
						(item, i) => `filter[${PAYLOAD.MARKETPLACE_ID}][${i}]=${item.id}`
					)
					.join("&")
			);
		}
		return "";
	}
	callInsightsAPI(path: string, payload: {}) {
		const filter = Object.keys(payload).reduce((res, key) => {
			return `${res}${key}=${payload[key]}&`;
		}, "");
		return this.http.callGetApi(
			`${path}?${filter.substring(0, filter.length - 1)}`
		);
	}

	public latestProductData(payload, optionalParams) {
		const userData = JSON.parse(
			this.commonFunctions.getDecodedData(this.localStorageService.get("user"))
		);
		let date_filter = `filter[${PAYLOAD.TIMELINE}]=${payload.timeline}`;
		if (typeof payload.timeline === "object" && 'startDate' in payload.timeline && 'endDate' in payload.timeline) {
			date_filter = `filter[${PAYLOAD.START_DATE}]=${payload.timeline.startDate}&filter[${PAYLOAD.END_DATE}]=${payload.timeline.endDate}`;
		}
		return this.http.callGetApi(`common/last-products-refresh-status?filter[${PAYLOAD.CURRENCY_ID}]=${payload.currency_id}&${date_filter}&filter[customer_id]=${userData["data"]["customer"]["id"]}${optionalParams ? optionalParams : ''}${this.appendMarketplace(payload)}`);
	}

	public getCommonFilters(path: string, payload: any) {
		const data = payload ? `?${this.commonFunctions.getPayloadFromObject(payload)}` : '';
		return this.http.callGetApi(`${path}${data}`);
	}

	public verifyUserByToken(token: string) {
		return this.http.callGetApi(`verify-user?token=${token}`);
	}
}
