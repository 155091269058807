import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
@Injectable({
	providedIn: "root"
})
export class NotificationService {
	constructor(private toastr: ToastrService) {}
	setMessage(code: number, message: any, timeOut = 4000) {
		switch (code) {
			case 1000: {
				this.toastr.success(message);
				break;
			}
			case 1100: {
				this.toastr.error(message);
				break;
			}
			case 1200: {
				this.toastr.info(message, null, { timeOut });
				break;
			}
			case 1500: {
				this.toastr.warning(message);
				break;
			}
		}
	}
	clearMessage() {
		this.toastr.clear();
	}
}
